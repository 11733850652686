@tailwind base;
@tailwind components;
@tailwind utilities;

.item {
	@apply
		bg-indigo-700 text-gray-100
		text-xl font-bold
		flex items-center content-center justify-center
		rounded
}

.body {
  padding: 0px;
  margin: 0px;
}
.root {
  padding: 0px;
  margin: 0px;
}

logo {
  clip-path: polygon(
    15% 18%,
    13% 76%,
    27% 77%,
    31% 56%,
    42% 55%,
    44% 76%,
    56% 79%,
    59% 17%,
    40% 17%,
    42% 43%,
    30% 43%,
    29% 19%
  );
}
.loginShadow {
  -webkit-box-shadow: 5px 5px 15px 5px #000000cb;
  box-shadow: 5px 5px 15px 5px #000000cb;
 
}
